<template>
    <div class="result">
        <!-- <step :stepNum="stepNum"></step> -->
        <div class="companyTitle">
            <div style="margin-left:31px">
                <i class="el-icon-circle-check" style="color:#34C758;font-size:27px;margin-right:10px"></i>
                <span style="font-size: 20px;">项目匹配完成，符合申报项目共{{list.length}}项</span>
            </div>
            <div style="margin-left:31px;margin-top:33px;margin-bottom:22px;font-size: 32px;font-weight: bold;">{{entityName}}</div>
            <div style="margin-left:31px;">
                <span style="font-size: 14px;color:rgba(121, 148, 192, 100);">资料越完整，匹配越精准， </span>
                <span style="color:#156ED0;border-bottom:1px solid #156ED0;font-size: 14px;" @click="againMatch">重新匹配</span>
            </div>
        </div>
        <div style="border-bottom:4px solid #000000;padding-bottom:30px;margin-top:27px">
            <span style="float:left;font-size: 20px;font-weight:bold">申报项目</span>
            <span style="float:left;font-size: 12px;color:#999;line-height: 30px;margin-left: 10px;">*项目排序越靠前，匹配度越高哦~</span>
            <i
              class="el-icon-download"
              style="float: right;font-size: 22px;cursor: pointer;"
              @click="handleDownload"
            ></i>
        </div>
        <div v-if="tableData.length > 0">
            <el-table :data="tableData" :header-cell-style="{'text-align':'center'}">
               <el-table-column type="index" label="序号" align="center" />
               <el-table-column  label="标题" prop="subtitle" align="left" width="420">
                  <template slot-scope="scope">
                    <span :class="scope.row.remain>=0?'remain':'hasas'">
                      {{scope.row.remain>0?'【剩余' + scope.row.remain + '天】': scope.row.remain == 0 ? '【今天截止】' : '【已截止】'}}
                    </span>
                    <span>{{scope.row.subtitle}}</span>
                  </template>
               </el-table-column>
               <el-table-column  label="主管部门" prop="deptName" align="center" />
               <el-table-column  label="政策级别" prop="zcJibie" align="center" >
                    <template slot-scope="scope">
                        <span>{{scope.row.zcJibie == 1?'中央级':scope.row.zcJibie == 2?'省级':scope.row.zcJibie == 3?'市级':scope.row.zcJibie == 4?'区级':'镇街级'}}</span>
                    </template>
               </el-table-column>
               <el-table-column  label="扶持方式" prop="supportModeList" align="center" width="100">
                <template slot-scope="scope">
                    <div v-for="(item,index) in scope.row.supportModeList" :key="index" style="display:flex;flex-wrap:flex">
                      <div v-if="item.tagName == '资金扶持'" class="zjfc">{{item.tagName}}</div>
                      <div v-if="item.tagName == '称号认定'" class="chrd">{{item.tagName}}</div>
                      <div v-if="item.tagName == '股权投资'" class="gqtz">{{item.tagName}}</div>
                      <div v-if="item.tagName == '减税降费'" class="jsjf">{{item.tagName}}</div>
                      <div v-if="item.tagName == '金融扶持'" class="jrfc">{{item.tagName}}</div>
                      <div v-if="item.tagName == '其他'" class="qt">{{item.tagName}}</div>
                    </div>
                </template>
               </el-table-column>
               <el-table-column  label="匹配依据" prop="gist" align="center" >
                 <template slot-scope="scope">
                    <el-button type="text" size="mini" @click="details(scope.row)">查看详情</el-button>
                 </template>
               </el-table-column>
            </el-table>
            <div class="fold" @click="handleFold"><i v-if="foldName == '展开更多'" class="el-icon-arrow-down"></i>{{foldName}}</div>
        </div>
        <div v-else>
            <el-empty description="暂无符合申报的政策"></el-empty>
            <div style="text-align:center"><el-button type="primary" round @click="againMatch" style="background:#156ED0">重新匹配</el-button></div>
        </div>
    </div>
</template>

<script>
// import step from '../components/index.vue'
// import { getMemberId, getVip } from '@/assets/public/utils/token'
export default {
  // components: {
  //   step
  // },
  data () {
    return {
      stepNum: 3,
      tableData: [],
      entityName: '广东政沣云计算有限公司',
      foldName: '',
      list: [],
      foldNum: 1,
      recordId: null
    };
  },
  mounted () {
    const { recordId } = JSON.parse(this.$route.query.parameter);
    this.tableData = JSON.parse(this.$route.query.parameter).formalList.slice(0, 10);
    this.list = JSON.parse(this.$route.query.parameter).formalList;
    this.entityName = JSON.parse(this.$route.query.parameter).entityName;
    this.foldName = JSON.parse(this.$route.query.parameter).formalList.length > 10 ? '展开更多' : '暂无更多数据';
    this.recordId = recordId;
  },
  methods: {
    /* 跳转详情 */
    details (rows) {
      // this.$router.push({
      //     path: "/formaldetails",
      //     query: {
      //         id:row.zhengceid,
      //         inputId:row.id
      //     },
      // });
      const id = rows.zhengceid;
      const inputId = rows.id;
      // if (this.$baseURL === 'https://javatest.po-o.cn') {
      //   window.open('https://ctxtn.po-o.cn/formaldetails/jiedu?id=' + id + '&inputId=' + inputId);
      // } else {
      //   window.open('https://po-o.cn/formaldetails/jiedu?id=' + id + '&inputId=' + inputId);
      // }
      window.open(`${process.env.VUE_APP_HTTP_URL}/formaldetails/jiedu?id=` + id + '&inputId=' + inputId);
    },
    /* 展开更多 */
    handleFold () {
      const n1 = this.foldNum * 10;
      const n2 = this.foldNum * 10 + 10;
      const arr = this.list.slice(n1, n2);
      if (this.tableData.length >= this.list.length) {
        this.foldName = '暂无更多数据';
      } else {
        this.tableData = this.tableData.concat(arr);
        this.foldNum = this.foldNum + 1;
      }
    },

    /* 重新匹配回到第二步 */
    againMatch () {
      this.$router.push({
        path: 'infoCompany',
        query: {
          entityName: this.entityName,
          navigateTo: '2'
        }
      });
    },
    // 下载
    handleDownload () {
      const saveLink = document.createElement('a');
      // 1：专业版，2：简易版
      saveLink.href = process.env.VUE_APP_BASEURL + '/pcp/projectmatch/exportResult?recordId=' + this.recordId + '&matchEdition=' + 1;
      saveLink.click();
    }
  }
};
</script>

<style lang="less" scoped>
    .result{
        padding-top: 32px;
    }
    .companyTitle{
        width: 1095px;
        height: 155px;
        // background-color: rgba(235, 241, 249, 100);
        background-image: url("~@/assets/pc/images/matching/result-banner.png");
        padding-top: 25px;
    }
    .zjfc {
    width: 90px;
    height: 22px;
    line-height: 22px;
    border-radius: 3px;
    border: 1px solid #ff7200;
    font-size: 12px;
    text-align: center;
    color: #ff7200;
    margin-bottom: 10px;
}

.chrd {
    width: 90px;
    height: 22px;
    line-height: 22px;
    border-radius: 3px;
    border: 1px solid #1faa53;
    font-size: 12px;
    text-align: center;
    color: #1faa53;
    margin-bottom: 10px;
}

.gqtz {
    width: 90px;
    height: 22px;
    line-height: 22px;
    border-radius: 3px;
    border: 1px solid #1f88f9;
    font-size: 12px;
    text-align: center;
    color: #1f88f9;
    margin-bottom: 10px;
}

.jsjf {
    width: 90px;
    height: 22px;
    line-height: 22px;
    border-radius: 3px;
    border: 1px solid #bf382f;
    font-size: 12px;
    text-align: center;
    color: #bf382f;
    margin-bottom: 10px;
}

.jrfc {
    width: 90px;
    height: 22px;
    line-height: 22px;
    border-radius: 3px;
    border: 1px solid #6cc2ed;
    font-size: 12px;
    text-align: center;
    color: #6cc2ed;
    margin-bottom: 10px;
}
.qt {
    width: 90px;
    height: 22px;
    line-height: 22px;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    font-size: 12px;
    text-align: center;
    color: rgba(0, 0, 0, 0.4);
    margin-bottom: 10px;
}
    .fold{
        height: 44px;
        line-height: 44px;
        background-color: rgba(249, 249, 249, 100);
        color: rgba(121, 148, 192, 100);
        font-size: 14px;
        text-align: center;
        font-family: Arial;
    }
    .remain{
        color: red;
        font-size: 12px;
        margin-right: 10px;
    }
.hasas{
        color: gray;
        font-size: 12px;
        margin-right: 10px;
    }
</style>
